import $ from 'jquery';
import {
	InnerSpinner,
	MainSpinner,
} from "/modules/spinner/external-access";

// TODO: Do something with these two... They're pretty much the same function but visually different? May be others as well...

const defaults = {
	animation: 'fade', // fade, none
	animationSpeed: 200, // how fast animations are
	text: null
};

let options;

export const loader = {
	show: function (opts) {
		options = $.extend({}, defaults, opts);

		if ($('.loader').length) {
			$('.loader').remove();
		}

		const elem = $('<div class="loader">');

		elem.append('<div class="loader-bg" />');

		const content = $('<div class="loader-content" />');
		content.append('<svg class="svg-gradient-rotate" width="80px" height="80px" viewBox="0 0 100 100"><clipPath id="clip"><path d="M 50 4 a 46 46 0 0 1 0 92 46 46 0 0 1 0 -92 v 8 a 38 38 0 0 0 0 76 38 38 0 0 0 0 -76"></path></clipPath><foreignObject x="0" y="0" width="100" height="100" clip-path="url(#clip)"><div style="--start-gradient-color: #6365DB; --end-gradient-color: #FFFFFF" class="w-full h-full rounded-full svg-gradient-div"></div></foreignObject></svg>');

		if (options.text) {
			content
				.addClass('has-text')
				.append('<div class="loader-text">' + options.text + '</div>');
		}

		elem.append(content);

		$(window).trigger('loader.open');

		if (options.animation === 'fade') {
			elem.hide().appendTo('body');

			elem.fadeIn(options.animationSpeed, function () {
				$(window).trigger('loader.opened');
			});
		} else {
			elem.appendTo('body');
			$(window).trigger('loader.opened');
		}

	},

	hide: function () {

		if (!$('.loader').length) {
			return false;
		}

		$(window).trigger('loader.close');

		if (options.animation === 'fade') {
			$('.loader').fadeOut(options.animationSpeed, function () {
				$(window).trigger('loader.closed');
				$(this).remove();
			});
		} else {
			$('.loader').remove();
			$(window).trigger('loader.closed');
		}
	}
};

// This does more than handle the menu spinner...

const loaderShowCount = new Map();

function increaseLoaderCount(spinnerSelector) {
	const value= 1 + (loaderShowCount.get(spinnerSelector) ?? 0);
	loaderShowCount.set(spinnerSelector, value);
	return value;
}
function decreaseLoaderCount(spinnerSelector) {
	const value = (loaderShowCount.get(spinnerSelector) ?? 1) - 1;
	loaderShowCount.set(spinnerSelector, value);
	return value;
}

export function showMenuSpinner(spinnerSelector, title, spinnerToggleClass) {
	if (increaseLoaderCount(spinnerSelector) !== 1) {
		return;
	}

	if (spinnerSelector === loader) {
		loader.show({ text: title });
	} else if (spinnerSelector === InnerSpinner || spinnerSelector === MainSpinner) {
		spinnerSelector.show();
	} else {
		if (!spinnerSelector) {
			spinnerSelector = "#menu-loader-spinner";
		}
		if (spinnerSelector === "#menu-loader-spinner") {
			spinnerToggleClass = "is-invisible";
		}
		if (!spinnerToggleClass) {
			spinnerToggleClass = "is-hidden";
		}
		const spinner = $(spinnerSelector);
		spinner.removeClass(spinnerToggleClass);
		spinner.attr('title', title);
	}
}

export function hideMenuSpinner(spinnerSelector, spinnerToggleClass) {
	if (decreaseLoaderCount(spinnerSelector) !== 0) {
		return;
	}

	if (spinnerSelector === loader) {
		loader.hide();
	} else if (spinnerSelector === InnerSpinner || spinnerSelector === MainSpinner) {
		spinnerSelector.hide();
	} else {
		if (!spinnerSelector) {
			spinnerSelector = "#menu-loader-spinner";
		}
		if (spinnerSelector === "#menu-loader-spinner") {
			spinnerToggleClass = "is-invisible";
		}
		if (!spinnerToggleClass) {
			spinnerToggleClass = "is-hidden";
		}
		const spinner = $(spinnerSelector);
		spinner.addClass(spinnerToggleClass);
	}
}
