import {
    useSpinnerContext
} from './general-spinner-context';

// We'll store the `setIsVisible` function here globally
let setInternalSpinnerState: ((isVisible: boolean) => void) | null = null;
let setMainSpinnerState: ((isVisible: boolean) => void) | null = null;

export const setMainSpinnerVisibility = (isVisible: boolean) => {
    if (setMainSpinnerState) {
        setMainSpinnerState(isVisible);
    } else {
        console.error("React context has not been initialized yet.");
    }
};

export const MainSpinner = {
    show: () => setMainSpinnerVisibility(true),
    hide: () => setMainSpinnerVisibility(false),
}

export const setInternalSpinnerVisibility = (isVisible: boolean) => {
    if (setInternalSpinnerState) {
        setInternalSpinnerState(isVisible);
    } else {
        console.error("React context has not been initialized yet.");
    }
};

export const InnerSpinner = {
    show: () => setInternalSpinnerVisibility(true),
    hide: () => setInternalSpinnerVisibility(false),
}

// This React component exposes the context setter globally
export const SpinnerContextInitializer = () => {
    const { setIsVisible } = useSpinnerContext();

    // Set functions to handle individual spinner visibility updates
    setMainSpinnerState = (isVisible: boolean) => {
        setIsVisible(prev => ({ ...prev, main: isVisible }));
    };

    setInternalSpinnerState = (isVisible: boolean) => {
        setIsVisible(prev => ({ ...prev, internal: isVisible }));
    };

    return null; // This component does not render anything
};