import * as React from "react";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";
import { 
    SpinnerProvider,
    useSpinnerContext
} from "/modules/spinner/general-spinner-context";
import {
    SpinnerContextInitializer,
} from "/modules/spinner/external-access";

const mainSpinnerContainer = document.getElementById("main-spinner");
const innerSpinnerContainer = document.getElementById("inner-spinner");

export interface SpinnerProps {
    startColor?: string;
    endColor?: string;
    size?: number;
    className?: string;
    centered?: boolean;
}

export const SpinnerSvg: React.FC<SpinnerProps> = ({
                                                       startColor = "#6365DB",
                                                       endColor = "#FFFFFF",
                                                       size = 80,
                                                  }) => {
    const gradientStyle = {
        "--start-gradient-color": startColor,
        "--end-gradient-color": endColor,
    } as React.CSSProperties;

    return (
        <svg 
            className="svg-gradient-rotate"
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 100 100" 
        >
            <clipPath id="clip">
                <path d="M 50 4 a 46 46 0 0 1 0 92 46 46 0 0 1 0 -92 v 8 a 38 38 0 0 0 0 76 38 38 0 0 0 0 -76"></path>
            </clipPath>
            <foreignObject
                x="0"
                y="0"
                width="100"
                height="100"
                clipPath="url(#clip)"
            >
                <div 
                    style={gradientStyle}
                    className="w-full h-full rounded-full svg-gradient-div"
                ></div>
            </foreignObject>
        </svg>
    );
};

export const Spinner: React.FC<SpinnerProps> = ({
                                                    centered = false,                                                    
                                                    ...rest
                                                }) => (
    <div
        className={`spinner-container ${centered ? 'spinner-centered' : ''}`}
        {...rest}
    >
        <div className="sticky pt-4 !bg-transparent h-fit">
            <SpinnerSvg centered={centered} {...rest}/>
        </div>
    </div>
);


export const InnerSpinner: React.FC = () => {
    const { isVisible } = useSpinnerContext();
    
    if (!isVisible?.internal){
        return null;
    }

    return (
        <div
            className="w-full h-full min-h-[50dvh] absolute inset-0 flex justify-center bg-[var(--wrapperColor)] dark:lg:bg-zinc-900 z-50"
        >
            <Spinner/>
        </div>);
}

export const MainSpinner: React.FC = () => {
    const { isVisible } = useSpinnerContext();

    if (!isVisible?.main){
        return null;
    }
    
    return (
        <div
            className="absolute inset-0 flex justify-center bg-[var(--wrapperColor)] lg:rounded-lg dark:lg:bg-zinc-900 z-50"
        >
            <Spinner/>            
        </div>);
}

function createInnerSpinner() {
    if (!innerSpinnerContainer) {
        return null;
    }

    return createPortal(
        <InnerSpinner/>,
        innerSpinnerContainer
    );
}

export function createSpinner() {
    if (!mainSpinnerContainer){
        return;
    }

    const children = [ createInnerSpinner() ];

    createRoot(mainSpinnerContainer).render(
        <SpinnerProvider>
            <SpinnerContextInitializer/>
            <MainSpinner/>
            { children }
        </SpinnerProvider>
    );    
}

createSpinner();